import styled from "styled-components"
import React from "react"

const Wrap = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-column: ${({col}) => col};
  grid-row: ${({row}) => row};
  
  @media(max-width: 1200px) {
    flex-basis: 45%;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  
  @media(max-width: 900px) {
    flex-basis: 60%;
  }
  
  @media(max-width: 550px) {
    flex-basis: 80%;
  }
  
  @media(max-width: 500px) {
    flex-basis: 90%;
  }
  
  @media(max-width: 450px) {
    flex-basis: 100%;
  }
  `

export default (props) => (
  <Wrap col={props.col} row={props.row}>
    {props.children}
  </Wrap>
)