import styled from "styled-components"
import { Link } from "gatsby"

export default styled(Link)`
  position: relative;
  font-size: 1.6rem;
  margin-top: 2rem;
  text-decoration: none;
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;
  display: inline-block;
  padding: 3vh 4vh;
  transition: all 100ms linear;
  
  span {
    position: absolute;
    opacity: 0;
    transform: translate(-20px, -2px);
    fill: ${({ theme }) => theme.white};
    transition: all 100ms linear;
  }
  
  :hover {
    padding-right: 3.5vw;
    box-shadow: 0 0 10px ${({ theme }) => theme.secondary};
  }
  
  :hover span {
    opacity: 1;
    transform: translate(10px, -2px);
  }
`;