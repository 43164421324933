import React from "react"
import Layout from "../components/organisms/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Hero from "../components/molecules/hero"
import OfferAng from "../components/molecules/offer-ang"
import OfferDe from "../components/molecules/offer-de"
import WhyUs from "../components/molecules/why-us"
import LastUpdate from "../components/molecules/last-update"

const IndexPage = ({ data }) => (
  <Layout
    cert1={data.cert1.childImageSharp.fluid}
    cert2={data.cert2.childImageSharp.fluid}
  >
    <SEO title="Strona główna" />
    <Hero img={data} />
    <LastUpdate />
    <OfferAng img={data.ang.childImageSharp.fluid} />
    <OfferDe img={data.de.childImageSharp.fluid} />
    <WhyUs img={data.exp.childImageSharp.fluid} />
  </Layout>
)

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero_img.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ang: file(relativePath: { eq: "index_ang.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    de: file(relativePath: { eq: "index_de.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    exp: file(relativePath: { eq: "index_exp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert1: file(relativePath: { eq: "cambridge-english-assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert2: file(relativePath: { eq: "direct-method.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 244, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default IndexPage
