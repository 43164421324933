import styled, { css } from "styled-components"

export default styled.article`
  position: absolute;
  width: 100%;
  ${props => props.top ?
  css`fill: #F0ECF1;
    top: -65px;`
  :
  css`fill: #ffffff;
    bottom: -5px;`
  }
  svg, path {
    width: 100%;
    height: 65px;
  }
`;