import styled, { css } from "styled-components"

export default styled.section`
  width: 50%;
  
  ${props => props.right ?
  css`background: #FFFFFF;
      text-align: right;
      padding-right: 10vw;`
  :
  css`background: #F0ECF1;
     text-align: left;
     padding-left: 10vw;
     padding-bottom: 20vh;
     padding-top: 10vh;`
  };
  
  @media(max-width: 1000px) {
    width: 100%;
    text-align: center;
    padding: 5vh 0;
  }
`;