import styled from "styled-components"

export default styled.h5`
  flex-basis: 100%;
  text-align: center;
  font-style: italic;
  font-size: 2rem;
  color: ${({ theme }) => theme.black};
  font-weight: 500;
  margin-bottom: 0;
`;