import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import ArticleOfferButton from "../atoms/article-offer-button"

const Container = styled.section`
  width: 100%;
  min-height: calc(100vh - 120px);
  display: flex;
  padding: 0 10vw;
  margin-bottom: 5vh;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 1200px) {
    min-height: calc(100vh - 80px);
    height: auto;
  }
`

const Hero = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`

const SvgImage = styled(Img)`
  flex-basis: 50%;
  max-width: 850px;
  border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
  @media (max-width: 1100px) {
    flex-basis: 100%;
    margin-top: 32px;
  }
`

const Content = styled.section`
  flex-basis: 50%;
  @media (max-width: 1100px) {
    flex-basis: 100%;
  }
`

const Heading = styled.h1`
  color: ${({ theme }) => theme.pink};
  font-weight: 600;
  font-size: 4rem;

  span {
    color: ${({ theme }) => theme.secondary};
  }
`
const Text = styled.h2`
  font-size: 1.8rem;
  font-weight: 400;
  font-family: "Cardo", sans-serif;
  width: 80%;
`

const ButtonContent = styled.section`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 8vh;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 4vh;
  }
`

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.black};
  padding: 1vh 2vh;
  fill: ${({ theme }) => theme.black};

  position: relative;

  ::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.black};
    bottom: -5px;
    left: 0;
    transform: scale(0);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
    transform-origin: left center;
  }

  :hover::before {
    transform: scale(1);
  }

  svg {
    margin-right: 2vh;
  }
`

const ButtonFeatured = styled(ArticleOfferButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-right: 3vw;

  span {
    position: static;
    opacity: 1;
    width: 24px;
    height: 24px;
    transform: none;
    margin-left: 10px;
  }

  :hover {
    padding-right: 4vh;
    box-shadow: 0 0 10px ${({ theme }) => theme.secondary};
  }

  :hover span {
    opacity: 1;
    transform: none;
  }

  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 4vh;
  }
`

export default ({ img }) => (
  <Container>
    <Hero>
      <Content>
        <Heading>
          Centrum Edukacji <span>AMUZO</span>
        </Heading>
        <Text>
          <b>Zajęcia z języka angielskiego</b> od najmłodszych lat.{" "}
          <b>Matematyka na wesoło</b> (od 6 roku życia). Korepetycje z
          matematyki, dla uczniów klas (szkoły podstawowej i liceum).{" "}
          <b>Przygotowanie do egzaminów ósmoklasisty i maturalnego</b> (podstawa
          i rozszerzenie). <b>Nauka czytania</b> to kurs, który wprowadzi Twoje
          dziecko w naukę czytania od podstaw, metodami zapewniającymi szybkie i
          trwałe efekty. Wspieramy rozwój, pamięć i koncentracje.
        </Text>
        <ButtonContent>
          <ButtonFeatured to="/kursy" title={"Nasze kursy"}>
            Nasze kursy
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
              </svg>
            </span>
          </ButtonFeatured>

          <Button to={"/kursy/zapisy"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            Zapisz się
          </Button>
        </ButtonContent>
      </Content>
      <SvgImage fluid={img.hero.childImageSharp.fluid} />
    </Hero>
  </Container>
)
