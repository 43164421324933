import ArticleWhyUsSubHeading from "../atoms/article-why-us-sub-heading"
import ArticleWhyUsHeading from "../atoms/article-why-us-heading"
import React, { Component } from "react"
import styled from "styled-components"
import Heding from "../atoms/newsletter-heading"
import Author from "../atoms/auth"
import Content from "../atoms/post-content"
import Button from "../atoms/button-link"
import api, { endpoint } from "../../api"
import Loading from "./loading"


const Container = styled.section`
  display: flex;
  margin-bottom: 100px;
  justify-content: space-between;
  align-items: stretch;
 flex-wrap: wrap;
  padding: 2vh 5vw;
`

const Card = styled.section`
  flex-basis: 30%;
  padding: 6px 10px;
  
  @media(max-width: 900px) {
    flex-basis: 100%;
    margin: 4vh 0;
  }
`

const ContentWithMargin = styled(Content)`
  margin-bottom: 20px;
`

export default class LastUpdate extends Component {

  state = {
    status: "load",
    posts: []
  }

  componentWillMount() {
    this.getPost()
  }

  getPost = () => {
    api(endpoint.lastPost)
      .then(res => {
        const posts = res.data

        this.setState({
          status: "ready",
          posts,
        })
      })
      .catch(err => {
        this.setState({ status: "fail" })
      })
  }

  embedPost = () => {
    if (this.state.status === "load") {
      return <Loading/>
    }

    return this.state.posts.map( (post, index) => (
      <Card itemscope itemType={"http://schema.org/Article"} key={index}>
        <Heding itemProp={"name"}>{post.title.rendered}</Heding>
        <Author itemProp={"author"}>Aleksandra Sydor • <time dateTime={post.date}
                                                             itemProp={"dateCreated"}>{post.date.substring(0, 10)}</time>
        </Author>
        <ContentWithMargin itemProp={"articleBody"} dangerouslySetInnerHTML={{ __html: post.excerpt.rendered.substring(0, 600) }} />
        <Button to={`/aktualnosci/${post.id},${post.slug}`} itemProp={"url"} title={post.title.rendered}>czytaj dalej</Button>
      </Card>
    ))
  }

  render = () => (
    <>
      <ArticleWhyUsSubHeading>
        świeże informacje
      </ArticleWhyUsSubHeading>
      <ArticleWhyUsHeading>
        Ostatnie aktualności
      </ArticleWhyUsHeading>
      <Container>
        {this.embedPost()}
      </Container>
    </>
  )
}
