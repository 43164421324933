import styled from "styled-components"

export default styled.article`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 100px 200px 100px 50px 300px 50px 100px 200px 100px 1fr;
  grid-template-rows: 200px 50px 200px 50px 200px;
  width: 100%;
  margin: 6vh 0 10vh;
  overflow: hidden;
  
  @media(max-width: 1200px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 6vh 5vw;
    justify-content: space-between;
    
    section:last-of-type {
      margin: auto;
    }
  }
  
  @media(max-width: 900px) {
    justify-content: center;
  }
`;