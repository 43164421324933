import Wave from "../atoms/article-wave"
import ArticleOfferContainer from "../atoms/article-offer-container"
import ArticleOfferWrap from "../atoms/article-offer-wrap"
import ArticleOfferHeading from "../atoms/article-offer-heading"
import ArticleOfferText from "../atoms/article-offer-text"
import ArticleOfferButton from "../atoms/article-offer-button"
import ArticleOffer from "../atoms/article-offer"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const SvgImage = styled(Img)`
  flex-basis: 40%;
  border-radius: 52% 48% 81% 19% / 47% 68% 32% 53%;
  max-width: 760px;
  @media (max-width: 1000px) {
    flex-basis: 100%;
  }
`

export default ({ img }) => (
  <ArticleOffer>
    <Wave top>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 4000 200"
        preserveAspectRatio="none"
      >
        <path d="M-.97 200.267S488.702-88.11 1554.238 30.667L2806.095 175.8c880.577 65.01 1197.43-145.143 1197.43-145.143v181.43z" />
      </svg>
    </Wave>
    <Wave bottom>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 4000 200"
        preserveAspectRatio="none"
      >
        <path d="M-.97 200.267S488.702-88.11 1554.238 30.667L2806.095 175.8c880.577 65.01 1197.43-145.143 1197.43-145.143v181.43z" />
      </svg>
    </Wave>
    <ArticleOfferContainer left>
      <ArticleOfferWrap left>
        <ArticleOfferHeading>kurs języka niemieckiego</ArticleOfferHeading>
        <ArticleOfferText>
          dzieci od 3 do 18 lat
          <br />
          zajęcia w małych grupach
          <br />
          od 30 do 60 minut zajęć
          <br />
          zajęcia nawet kilka razy w tygodniu
          <br />
          lekcje multimedialne
        </ArticleOfferText>
        <ArticleOfferButton to="/kursy" title={"Nasze kursy"}>
          sprawdź
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
            </svg>
          </span>
        </ArticleOfferButton>
      </ArticleOfferWrap>
      <SvgImage fluid={img} />
    </ArticleOfferContainer>
  </ArticleOffer>
)
