import styled, { css } from "styled-components"

export default styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  ${props => props.right ?
  css`background: #FFFFFF;
      justify-content: flex-end;
      flex-wrap: wrap-reverse;
      margin-bottom: 20vh;
      margin-top: 8vh;`
  :
  css`background: #F0ECF1;
      justify-content: flex-start;
      flex-wrap: wrap;`
  };
`;