import ArticleOfferContainer from "../atoms/article-offer-container"
import ArticleOfferWrap from "../atoms/article-offer-wrap"
import ArticleOfferHeading from "../atoms/article-offer-heading"
import ArticleOfferText from "../atoms/article-offer-text"
import ArticleOfferButton from "../atoms/article-offer-button"
import ArticleOffer from "../atoms/article-offer"
import React from "react"
import styled from "styled-components"
import ArticleWhyUsSubHeading from "../atoms/article-why-us-sub-heading"
import ArticleWhyUsHeading from "../atoms/article-why-us-heading"
import Img from "gatsby-image"

const SvgImage = styled(Img)`
  flex-basis: 40%;
  border-radius: 27% 73% 20% 80% / 60% 45% 55% 40%;
  max-width: 850px;
  @media (max-width: 1000px) {
    flex-basis: 100%;
  }
`

export default ({ img }) => (
  <>
    <ArticleWhyUsSubHeading>nasza oferta</ArticleWhyUsSubHeading>
    <ArticleWhyUsHeading>W czym jesteśmy najlepsi?</ArticleWhyUsHeading>
    <ArticleOffer>
      <ArticleOfferContainer right>
        <SvgImage fluid={img} />
        <ArticleOfferWrap right>
          <ArticleOfferHeading>kurs języka angielskiego</ArticleOfferHeading>
          <ArticleOfferText>
            dzieci od 3 do 18 lat
            <br />
            stałe grupy
            <br />
            zajęcia raz lub dwa razy w tygodniu
            <br />
            30 - 60 minut zajęć
            <br />
            małe grupy od 4 do 8 dzieci
            <br />
            lekcje multimedialne
          </ArticleOfferText>
          <ArticleOfferButton to="/kursy" title={"Nasze kursy"}>
            sprawdź
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
              </svg>
            </span>
          </ArticleOfferButton>
        </ArticleOfferWrap>
      </ArticleOfferContainer>
    </ArticleOffer>
  </>
)
