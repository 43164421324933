import ArticleWhyUsSubHeading from "../atoms/article-why-us-sub-heading"
import ArticleWhyUsHeading from "../atoms/article-why-us-heading"
import Card from "../atoms/article-why-us-card"
import CardNumber from "../atoms/article-why-us-card-number"
import CardHeading from "../atoms/article-why-us-card-heading"
import CardText from "../atoms/article-why-us-card-text"
import ArticleWhyUs from "../atoms/article-why-us"
import React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"

const Image = styled(Img)`
  border-radius: 55% 45% 65% 35% / 50% 34% 66% 50%;
  width: 100%;
`

const ImageWrap = styled.section`
  grid-column: 5/8;
  grid-row: 3/6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${Image} {
    width: 100%;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`

const Circle = styled.svg`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  width: ${({ w }) => w};
  height: ${({ h }) => h};
  fill: ${({ theme }) => theme.pink + "70"};
  z-index: -1;
  ${({ hide }) =>
    hide &&
    css`
      @media (max-width: 1000px) {
        display: none;
      }
    `}

  ${({ sec, theme }) =>
    sec &&
    css`
      fill: ${theme.secondary + "70"};
    `}
`

const CircleWrap = props => (
  <Circle
    viewBox="0 0 100 100"
    top={props.top}
    left={props.left}
    w={props.w}
    h={props.h}
    hide={props.hide}
    sec={props.sec}
  >
    <circle cx="50" cy="50" r="50" />
  </Circle>
)

export default ({ img }) => (
  <>
    <ArticleWhyUsSubHeading>5 powodów</ArticleWhyUsSubHeading>
    <ArticleWhyUsHeading>Dlaczego my?</ArticleWhyUsHeading>
    <ArticleWhyUs>
      <ImageWrap>
        <Image fluid={img} />
      </ImageWrap>
      <CircleWrap top={"0"} left={"10%"} w={"10px"} h={"10px"} sec />
      <CircleWrap top={"90%"} left={"90%"} w={"15px"} h={"15px"} />
      <CircleWrap top={"60%"} left={"84%"} w={"15px"} h={"15px"} />
      <CircleWrap top={"30%"} left={"96%"} w={"100px"} h={"100px"} hide sec />
      <CircleWrap top={"60%"} left={"-5%"} w={"150px"} h={"150px"} hide />
      <CircleWrap top={"48%"} left={"15%"} w={"13px"} h={"13px"} />
      <CircleWrap top={"98%"} left={"30%"} w={"5px"} h={"5px"} />
      <CircleWrap top={"70%"} left={"80%"} w={"5px"} h={"5px"} sec />
      <CircleWrap top={"30%"} left={"70%"} w={"20px"} h={"20px"} />
      <CircleWrap top={"30%"} left={"20%"} w={"11px"} h={"11px"} sec />
      <CircleWrap top={"16%"} left={"30%"} w={"18px"} h={"18px"} sec />
      <CircleWrap top={"16%"} left={"90%"} w={"25px"} h={"25px"} />
      <CircleWrap top={"50%"} left={"30%"} w={"30px"} h={"30px"} />
      <CircleWrap top={"10%"} left={"74%"} w={"55px"} h={"55px"} />
      <CircleWrap top={"10%"} left={"17%"} w={"30px"} h={"30px"} sec />
      <CircleWrap top={"95%"} left={"10%"} w={"28px"} h={"28px"} sec />
      <Card col={"6/7"} row={"1/3"}>
        <CardNumber>#1</CardNumber>
        <CardHeading>Doświadczenie</CardHeading>
        <CardText>
          Jesteśmy szkołą z wieloletnim doświadczeniem, a opinie naszych
          absolwentów są najlepszą wizytówką naszej szkoły. Promujemy
          kulturę krajów angielskiego obszaru językowego.
        </CardText>
      </Card>
      <Card col={"2/4"} row={"3/4"}>
        <CardNumber>#2</CardNumber>
        <CardHeading>Wyposażenie</CardHeading>
        <CardText>
          Uczymy skutecznie i nowocześnie. Do nauczania używamy środków
          audiowizualnych, inspirujących pomocy dydaktycznych i nowatorskich
          podręczników.
        </CardText>
      </Card>
      <Card col={"9/11"} row={"3/4"}>
        <CardNumber>#3</CardNumber>
        <CardHeading>Słuchamy was</CardHeading>
        <CardText>
          Współpraca z rodzicami jest jednym z naszych priorytetów. Mają oni
          możliwość otrzymania bieżących informacji o postępach swoich dzieci.
        </CardText>
      </Card>
      <Card col={"3/5"} row={"5/6"}>
        <CardNumber>#4</CardNumber>
        <CardHeading>Duży wybór kursów</CardHeading>
        <CardText>
          Oferujemy pełen zakres kursów językowych dla wszystkich grup wiekowych
          (dzieci, młodzieży, dorosłych), kursy przygotowujące do egzaminów
          gimnazjalnych, matury.
        </CardText>
      </Card>
      <Card col={"8/10"} row={"5/6"}>
        <CardNumber>#5</CardNumber>
        <CardHeading>Program nauczania</CardHeading>
        <CardText>
          Prowadzimy zajęcia w oparciu o odpowiednio przygotowane programy
          nauczania. Zapewniamy ciągłość nauki: od 3 lat aż do końca szkoły
          średniej.
        </CardText>
      </Card>
    </ArticleWhyUs>
  </>
)
